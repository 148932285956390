import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./control-device-dialog.css";
import DialogActions from "@material-ui/core/DialogActions";
import { usePromiseTracker } from "react-promise-tracker";
import LoadingSpinner from "../../utilities/loading-spinner";
import TextField from "@material-ui/core/TextField";
import DeviceService from "../../../services/device";

export default function UpdateDeviceAlias(props) {
  let { onClose, device, open } = props;
  // promise tracker
  const [selectedDevice, setSelectedDevice] = useState({});
  useEffect(() => {
    if (device) { setSelectedDevice(device); }
  }, [props])
  const { promiseInProgress } = usePromiseTracker();
  const handleClose = async (d) => {
    if (d == null) onClose(d);
    await DeviceService.updateDeviceAlias(d);
    onClose(d);
  }

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Control device : {device?.managedDeviceAlias}
        </DialogTitle>

        {device == null || promiseInProgress === true ? (
          <LoadingSpinner />
        ) : (
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="managedDeviceAlias"
              label="Device alias"
              type="text"
              error={
                device.managedDeviceAlias == null ||
                device.managedDeviceAlias.length == 0
              }
              helperText={
                device.managedDeviceAlias == null ||
                device.managedDeviceAlias.length == 0
                  ? "Alias can not be empty"
                  : ""
              }
              value={selectedDevice.managedDeviceAlias}
              onChange={(e) => {
                if (device == null) return;
                device.managedDeviceAlias = e.target.value;
                setSelectedDevice({...device});
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="order"
              label="Order"
              type="number"
              value={selectedDevice.order}
              onChange={(e) => {
                if (device == null) return;
                device.order = parseInt(e.target.value);
                setSelectedDevice({...device});
              }}
              fullWidth
            />
              <TextField
              autoFocus
              margin="dense"
              id="floor"
              label="Floor"
              type="number"
              value={selectedDevice.floor}
              onChange={(e) => {
                if (device == null) return;
                device.floor = parseInt(e.target.value);
                setSelectedDevice({...device});
              }}
              fullWidth
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => handleClose(device)} color="primary">
            OK
          </Button>
          <Button onClick={() => onClose(null)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
