import React, { Component } from "react";
import Login from "../components/login";
import AuthService from "../services/auth";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import Home from "../components/home";
import { DeviceList } from "../components/device";
import { CalendarList, CalendarDetail } from "../components/calendar";
import { UserList, UserDetail } from "../components/admin/user";
import { TenantDetail } from "../components/admin/tenant";
import IntelModConfig from "../components/intel-mods";

const PublicRoute = (props) => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/login" component={Login}></Route>
      <ProtectedRoute path="/" component={Home}></ProtectedRoute>
    </Switch>
  </BrowserRouter>
);

const PrivateRoute = (props) => (
  // <BrowserRouter>
  <Switch>
    {/* <Route exact path="/devices" component={DeviceList}></Route> */}
    <ProtectedRoute exact path="/" component={DeviceList}></ProtectedRoute>
    <ProtectedRoute
      exact
      path="/devices"
      component={DeviceList}
    ></ProtectedRoute>
    <ProtectedRoute
      exact
      path="/calendar"
      component={CalendarList}
    ></ProtectedRoute>
    <ProtectedRoute
      exact
      path="/calendar-detail"
      component={CalendarDetail}
    ></ProtectedRoute>
    <ProtectedRoute exact path="/users" component={UserList}></ProtectedRoute>
    <ProtectedRoute
      exact
      path="/user-detail"
      component={UserDetail}
    ></ProtectedRoute>
    <ProtectedRoute exact path="/tenant-detail" component={TenantDetail} />
    <ProtectedRoute exact path="/intel-mod" component={IntelModConfig} />
  </Switch>
  // </BrowserRouter>
);

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      AuthService.isAuthenticate() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

export { PublicRoute, PrivateRoute };
