import axios from "../axios-client";
import AppConfig from "../../config";
import RequestService from '../request.service';

class DeviceService {
  async getDevices(filter) {
    const response = await axios.post(AppConfig.device.listDeviceUrl, filter);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = this.fetchDevicesData(response.data);

    return rs;
  }

  async fetchDevicesData(data) {
    const result = [...data];
    result.sort((a, b) => {
      if (a.order && b.order && a.order > b.order) {
        return 1;
      }
      if (a.order && b.order && a.order < b.order) {
        return -1;
      }
      if (a.managedDeviceAlias > b.managedDeviceAlias) {
        return 1;
      }
      if (a.managedDeviceAlias < b.managedDeviceAlias) {
        return -1;
      }
      return 0;
    });
    result.forEach((el) => this.fetchDeviceData(el));
    return result;
  }

  fetchDeviceData(el) {
    el.monitor.aqi = this.convertPm25ToAqui(el.monitor.pm25);
    el.monitor.serverity = this.getAqiServerityClass(el);
    el.monitor.status = this.getAqiStatus(el);

    this.fetchHeatAir(el);
  }

  async getDevice(id) {
    const response = await axios.get(AppConfig.device.deviceUrl + "/" + id);

    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  async turnOnDevice(device) {
    const response = await axios.post(AppConfig.device.turnOnDeviceURL, device);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    this.fetchDeviceData(rs);
    return rs;
  }

  async turnOffDevice(device) {
    const response = await axios.post(
      AppConfig.device.turnOffDeviceURL,
      device
    );
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    this.fetchDeviceData(rs);
    return rs;
  }

  async setFan(device, rank) {
    const response = await axios.post(
      AppConfig.device.setFanUrl + "/" + rank,
      device
    );
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    this.fetchDeviceData(rs);
    return rs;
  }

  async resetFilter(device, filterindex) {
    const response = await axios.post(
      AppConfig.device.resetFilterUrl + "/" + filterindex,
      device
    );
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  async setAir(device) {
    const response = await axios.post(AppConfig.device.setAirUrl, device);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    this.fetchDeviceData(rs);
    return rs;
  }

  async updateDeviceAlias(device) {
    const response = await RequestService.patch(AppConfig.device.updateDeviceAliasUrl, device);
  }

  getHeatByFunc(func) {
    return func && func.endsWith(AppConfig.device.func.heatOn);
  }

  getAirCommandByFunc(func) {
    if (func) {
      var airCode = func.substring(4).slice(0, -5);
      return airCode;
    } else return null;
  }

  fetchHeatAir(device) {
    device.heat = this.getHeatByFunc(device.monitor.func);
    device.air = this.getAirCommandByFunc(device.monitor.func);
  }

  getDeviceRankAirDisplayLabel(device) {
    if (device.type == 1) {
      return this.getDeviceRankAirDisplayLabelTypeExternal(device);
    } else {
      return this.getDeviceRankAirDisplayLabelTypeInternal(device);
    }
  }

  getDeviceRankAirDisplayLabelTypeExternal(device) {
    if (device.monitor.rank <= 0 || device.monitor.isOnline === false || device.monitor.mode == 17 || device.monitor.mode == 18) {
      return " OFF";
    }
    let airStatus = "";
    if (device.air != null && device.air != "") {
      airStatus += " / " + this.getAirPercentageExternal(device.air) + "%";
    }
    return " " + device.monitor.rank + airStatus;
  }

  getDeviceRankAirDisplayLabelTypeInternal(device) {
    if (device.monitor.rank <= 0 || device.monitor.isOnline === false || device.monitor.mode == 17 || device.monitor.mode == 18) {
      return ": OFF";
    }
    return ": " + device.monitor.rank ;
  }

  getAirPercentageExternal(air) {
    switch (air) {
      case "010":
        return 0;
      case "080":
        return 25;
      case "100":
        return 50;
      case "180":
        return 75;
      case "200":
        return 100;
      default:
        return 0;
    }
  }

  buildFuncFromAirHeat(air, heat) {
    const heatCommand = heat == true ? "40000" : "10000";
    const result = AppConfig.device.func.prefix + air + heatCommand;
    return result;
  }

  buildFuncFromAirHeatCode(aircode, heatcode) {
    return AppConfig.device.func.prefix + aircode + heatcode;
  }

  async getDeviceAlias() {
    const response = await axios.get(AppConfig.device.getAliasUrl);

    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }

    const rs = response.data;
    return rs;
  }

  async getDeviceAliasInfo() {
    return RequestService.get(AppConfig.device.getDeviceAliasUrl);
  }

  async executeDeviceAlias(control) {
    const response = await axios.post(
      AppConfig.device.executeAliasUrl,
      control
    );

    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }

    const rs = response.data;
    return rs;
  }

  getDisplayProductNo(productNo) {
    if (productNo == null || productNo == undefined) {
      return "";
    }

    var length = 3;
    let reg = new RegExp(`(.{${length}})`, "g");
    var productNoArr = productNo.match(reg);
    return productNoArr.join("-");
  }

  convertPm25ToAqui(pm25) {
    return AppConfig.configs.Aqi[pm25];
  }

  getAqiServerityClass(d) {
    if (!d.monitor.isOnline) {
      return "darkmod-disabled";
    }
    var serverity = this.getDeviceServerity(d);
    switch (serverity) {
      case 0:
        return "severity-good";
      case 1:
        return "severity-moderate";
      case 2:
        return "severity-sensitive-unhealthy";
      case 3:
        return "severity-unhealthy";
      case 4:
        return "severity-very-unhealthy";
      case 5:
        return "severity-hazardous";
      default:
        return "";
    }
  }

  getDeviceServerity(d) {
    if (!d.monitor.isOnline) {
      return -1;
    }
    const aqiServerity = this.getAqiServerity(d.monitor.aqi);
    const co2Serverity = this.getCo2Serverity(d.monitor.co2);
    return aqiServerity > co2Serverity ? aqiServerity : co2Serverity;
  }

  getAqiServerity(aqi) {
    if (aqi == null) return -1;
    return Math.floor(aqi / 50);
  }

  getCo2Serverity(co2) {
    if (co2 >= 0 && co2 <= 1000) {
      return 0;
    }
    if (co2 >= 1001 && co2 <= 1500) {
      return 2;
    }
    if (co2 >= 1501 && co2 <= 2500) {
      return 3;
    }
    if (co2 >= 2501 && co2 <= 5000) {
      return 4;
    }
    if (co2 >= 5001) {
      return 5;
    }
    return -1;
  }

  getAqiStatus(d) {
    if (!d.monitor.isOnline) {
      return "Offline";
    }
    var serverity = this.getDeviceServerity(d);
    switch (serverity) {
      case 0:
        return "Good";
      case 1:
        return "Moderate";
      case 2:
        return "Unhealthy";
      case 3:
        return "Unhealthy";
      case 4:
        return "Very-unhealthy";
      case 5:
        return "Hazardous";
      default:
        return "";
    }
  }

  // group device by their serverity
  aggregateDevices(devices) {
    let result = [0,0,0,0,0,0];
    for (let i = 0; i < devices.length; i++) {
      const el = devices[i];
      const severity = this.getDeviceServerity(el);
      if (severity >= 0 && severity < 6) {
        result[severity] = result[severity] + 1;
      }
    }
    return result;
  }

}

export default new DeviceService();
