import RequestService from '../request.service';
import AppConfig from "../../config";

class DeviceHistoryService {
    async getDeviceHourlyHistory(deviceId, hours = 6) {
        const testUrl = `${AppConfig.deviceHistory.getHourlyDeviceHistory}/${deviceId}/${hours}`;
        let deviceAggregateHourly = await RequestService.get(`${AppConfig.deviceHistory.getHourlyDeviceHistory}/${deviceId}/${hours}`);
        return deviceAggregateHourly;
    }
}

export default new DeviceHistoryService();