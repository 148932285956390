import axios from "axios";
import AppConfig from "../../config";
import jwt_decode from "jwt-decode";
import axiosClient from '../axios-client';

export class AuthService {
  async authenticate(username, password) {
    try {
      const response = await axios.post(
        AppConfig.auth.tokenUrl,
        { username: username, password: password, remember: false }
        // , {
        //     headers: {
        //         'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        //     }
        // }
      );

      if (response.status != 200) {
        return false;
      } else {
        const token = response.data.accessToken;
        localStorage.setItem(AppConfig.auth.tokenKey, token);
        localStorage.setItem(AppConfig.auth.username, response.data.userName);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  async changePassword(changePasswordInput) {
    const response = await axiosClient.put(AppConfig.auth.changePasswordUrl, changePasswordInput);  
    if (response.status != 200) {
        throw new Error('Error code ' + response.status);
    }
    const rs = response.data;
    return rs;
  }

  signout() {
    localStorage.removeItem(AppConfig.auth.tokenKey);
  }

  isAuthenticate() {
    return localStorage.getItem(AppConfig.auth.tokenKey) != null;
  }

  getToken() {
    return localStorage.getItem(AppConfig.auth.tokenKey);
  }

  getRole() {
    const token = localStorage.getItem(AppConfig.auth.tokenKey);
    if (token == null) {
      return null;
    }
    const decodedJwt = jwt_decode(token);
    const role = decodedJwt[AppConfig.configs.jwtPayload.role];
    return role;
  }

  getPermission(formName) {
    let permission = {
      canEdit: false,
      canRead: false,
      canAdd: false,
      canDelete: false,
    };
    const role = this.getRole();

    if (role == "admin" || role == "Admin") {
        permission.canAdd =  true;
        permission.canDelete = true;
        permission.canEdit = true;
    }
    return permission;
  }
}

export default new AuthService();
