import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import DialogContent from "@material-ui/core/DialogContent";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import "./control-device-dialog.css";
import DialogActions from "@material-ui/core/DialogActions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppConfig from "../../../config";
import deviceService from "../../../services/device/device.service";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingSpinner from "../../utilities/loading-spinner";
import Checkbox from "@material-ui/core/Checkbox";
import { Progress } from "react-sweet-progress";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Grid from "@material-ui/core/Grid";
import AuthService from "../../../services/auth";
import DeviceService from "../../../services/device";

export default function ControlDeviceDialog(props) {
  const [device, setDevice] = useState(null);
  let { onClose, selectedDevice, open } = props;
  // promise tracker
  const { promiseInProgress } = usePromiseTracker();
  const permissions = AuthService.getPermission();
  const handleClose = (d) => {
    onClose(d);
  };
  useEffect(() => {
    if (selectedDevice == null) return;
    setDevice(selectedDevice);
  }, [selectedDevice]);

  const handleFanspeedChange = async (event) => {
    try {
      var updatedDevice = await trackPromise(
        deviceService.setFan(device, event.target.value)
      );
      // onClose(updatedDevice);
      setDevice(updatedDevice);
    } catch (error) {
      alert(error);
    }
  };

  const handleAirChange = async (event) => {
    try {
      var air = event.target.value;
      var func = deviceService.buildFuncFromAirHeat(air, device.heat);
      device.monitor.func = func;
      var updatedDevice = await trackPromise(deviceService.setAir(device));
      // onClose(device);
      setDevice(updatedDevice);
    } catch (error) {
      alert(error);
    }
  };

  const handleHeatChange = async (event) => {
    try {
      var heat = event.target.checked;
      var func = deviceService.buildFuncFromAirHeat("000", heat);
      device.monitor.func = func;
      var updatedDevice = await trackPromise(deviceService.setAir(device));
      // onClose(device);
      setDevice(updatedDevice);
    } catch (error) {
      alert(error);
    }
  };

  // device is offline or is already turned on => can not turn on
  const isDisabledTurnOn = (dv) => {
    // if (dv == null || !dv.monitor.isOnline) {
    if (dv == null || dv.monitor.isOnline === false) {
      return true;
    }
    return dv.monitor.mode != 17;
  };

  // device is offline or is already turn off => can not turn off
  const isDisabledTurnOff = (dv) => {
    if (dv == null || dv.monitor.isOnline === false) {
      return true;
    }
    return dv.monitor.mode === 17;
  };

  // device is offline or is turn off => can not control
  const isDisabledDeviceControl = (dv) => {
    if (
      dv == null ||
      dv.monitor.mode === AppConfig.device.mode.offline ||
      dv.monitor.isOnline === false
    ) {
      return true;
    }
  };

  const turnOnDevice = async () => {
    try {
      var updatedDevice = await trackPromise(
        deviceService.turnOnDevice(device)
      );
      // onClose(updatedDevice);
      setDevice(updatedDevice);
    } catch (error) {
      alert(error);
    }
  };

  const turnOffDevice = async () => {
    try {
      var updatedDevice = await trackPromise(
        deviceService.turnOffDevice(device)
      );
      // onClose(updatedDevice);
      setDevice(updatedDevice);
    } catch (error) {
      alert(error);
    }
  };

  const getFilterRemaining = (filter) => {
    return Math.round(filter.life / 24);
  };

  const getFilterStatus = (filter) => {
    var filterLife = ((filter.lifespan - filter.life) * 100) / filter.lifespan;
    if (filterLife <= 80) return "success";
    return "error";
  };

  const resetFilter = async (index) => {
    if (window.confirm("Do you confirm to reset this filter?"))
      try {
        var updatedDevice = await trackPromise(
          deviceService.resetFilter(device, index)
        );
        onClose(updatedDevice);
      } catch (error) {
        alert(error);
      }
  };

  if (!permissions.canEdit) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          Insufficient permissions
        </DialogTitle>
        <DialogContent>
          <Button color="secondary">
            Insufficient permissions to access this View!
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Control device : {device?.managedDeviceAlias}
      </DialogTitle>

      {device == null || promiseInProgress === true ? (
        <LoadingSpinner />
      ) : (
        <DialogContent>
          <div>
            <div>
              <i>
                {"ProductNo: " +
                  DeviceService.getDisplayProductNo(
                    device?.managedDeviceProductNo
                  ) +
                  ""}
              </i>
            </div>
            <label>Control</label>
            <div>
              <Button
                color="secondary"
                disabled={isDisabledTurnOff(device)}
                onClick={turnOffDevice}
              >
                <PowerSettingsNewIcon />
                Turn off
              </Button>
              <Button
                color="primary"
                disabled={isDisabledTurnOn(device)}
                onClick={turnOnDevice}
              >
                <PowerSettingsNewIcon />
                Turn on
              </Button>
            </div>
          </div>
          <div>
            <label>Fan speed</label>
            <div>
              <RadioGroup
                aria-label="Select fan speed"
                name="fanspeed"
                value={device?.monitor.rank}
                onChange={handleFanspeedChange}
                row
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Rank 1"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Rank 2"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label="Rank 3"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value={4}
                  control={<Radio />}
                  label="Rank 4"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value={5}
                  control={<Radio />}
                  label="Rank 5"
                  disabled={isDisabledDeviceControl(device)}
                />
              </RadioGroup>
            </div>
          </div>
          <div>
            <label>Airflow mode</label>
            <div>
              <RadioGroup
                aria-label="Select air mode"
                name="air mode"
                value={device?.air}
                onChange={handleAirChange}
                row
              >
                <FormControlLabel
                  value="010"
                  control={<Radio />}
                  label="Internal"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value="080"
                  control={<Radio />}
                  label="25% external"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value="100"
                  control={<Radio />}
                  label="50% external"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value="180"
                  control={<Radio />}
                  label="75% external"
                  disabled={isDisabledDeviceControl(device)}
                />
                <FormControlLabel
                  value="200"
                  control={<Radio />}
                  label="External"
                  disabled={isDisabledDeviceControl(device)}
                />
              </RadioGroup>
            </div>
            <label>Heat</label>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={device?.heat}
                    onChange={handleHeatChange}
                    name="checkedA"
                    disabled={isDisabledDeviceControl(device)}
                  />
                }
                label="Heat"
              />
            </div>
            <div>
              {device?.monitor?.filters?.map((filter, index) => (
                <Grid container spacing={3} key={filter.name}>
                  <Grid item xs={9}>
                    <div>
                      <div className="lblLeftLabel">
                        <div className="float-left">
                          <i>{filter.name}</i>
                        </div>
                        <i className="float-left">
                          {" : " + getFilterRemaining(filter) + " days left"}
                        </i>
                      </div>
                      <span>
                        <Progress
                          percent={(filter.life * 100) / filter.lifespan}
                          status={getFilterStatus(filter)}
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      color="secondary"
                      onClick={() => resetFilter(index)}
                    >
                      <RotateLeftIcon /> Reset
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => onClose(device)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ControlDeviceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object,
};
