import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CalendarService from "../../services/calendar";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import "./calendar-list.css";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CalendarList(props) {
  const classes = useStyles();
  const [calendars, setCalendars] = useState([]);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    const rs = await CalendarService.getAll();
    console.log(rs);
    setCalendars(rs);
  };

  const getDisplayDays = (days) => {
    let result = "";
    days.forEach((d) => {
      result += getDisplayDayOfWeek(d) + " | ";
    });
    return result;
  };

  const getDisplayDayOfWeek = (index) => {
    switch (index) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
    }
  };

  const viewDetail = (c) => {
    props.history.push({
      pathname: "/calendar-detail",
      search: "?id=" + c.id,
      state: { calendarJob: c },
    });
  };

  const deleteCalendar = async (id) => {
    if (!window.confirm("Are you sure to delete this calendar?")) {
      return;
    }
    try {
      var response = await CalendarService.delete(id);
      search();
      alert("Change request successful!");
    } catch (error) {
      alert(error);
    }
  };

  const createNew = () => {
    props.history.push("/calendar-detail");
  };

  return (
    <React.Fragment>
      <h3>Calendar list</h3>
      <div>
        <Button color="primary" variant="contained" onClick={createNew}>
          <AddCircleOutlineIcon /> Add calendar
        </Button>
      </div>
      <Grid container>
        <Grid xs={12} item>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Days of week</TableCell>
                  <TableCell align="right">Hour - Minute</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Active</TableCell>
                  <TableCell align="right">Fan speed</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calendars.map((calendar, index) => (
                  <TableRow className="active-row" key={index}>
                    <TableCell component="th" scope="row">
                      {getDisplayDays(calendar.daysOfWeek)}
                    </TableCell>
                    <TableCell align="right">
                      {calendar.hour +
                        " (hour) | " +
                        calendar.minute +
                        " (minute) "}
                    </TableCell>
                    <TableCell align="right">
                      {calendar.jobType == 1 ? "TURN ON" : "TURN OFF"}{" "}
                    </TableCell>
                    <TableCell align="right">
                      {calendar.isEnabled ? (
                        <DoneOutlineOutlinedIcon />
                      ) : (
                        <HighlightOffOutlinedIcon />
                      )}
                    </TableCell>
                    <TableCell align="center">{calendar.rank}</TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        onClick={() => viewDetail(calendar)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        color="secondary"
                        onClick={() => deleteCalendar(calendar.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
