import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import UserService from "../../../services/user";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 690,
    // padding: 9,
    // backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  foot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const roles = ["admin", "guest"];

export default function UserDetail(props) {
  let user = props?.location?.state?.user;
  let isCreate = false;
  if (user == null) {
    isCreate = true;
    user = {
      userName: "",
      email: "",
      roles: [],
      createdDate: null,
    };
  }
  const [state, setState] = useState({
    user: user,
    errors: {
      usernameErr: "",
      emailErr: "",
    },
  });

  const handleChange = (e, type) => {
    switch (type) {
      case "username":
        handleUsername(e.target.value);
        return;
      case "email":
        handleEmail(e.target.value);
        return;
      case "role":
        handleRoleChange(e.target.name, e.target.checked);
        return;
      default:
        return;
    }
  };

  const classes = useStyles();

  const submit = async () => {
    if (validateData() === true) {
      try {
        if (isCreate) {
          await UserService.create(state.user);
        } else {
          await UserService.update(state.user);
        }
        alert("Change request successful!");
      } catch (error) {
        alert(error);
      }
    }
  };

  const goBack = () => {};

  const handleUsername = (un) => {
    const nextState = {
      ...state,
      user: {
        ...state.user,
        userName: un,
      },
    };

    if (un == null || un.length == 0) {
      nextState.errors.usernameErr = "Username is not valid";
    } else {
      nextState.errors.usernameErr = "";
    }
    setState(nextState);
  };

  const handleRoleChange = (name, checked) => {
    let nextRoles = [...state.user.roles];
    if (checked != true) {
      nextRoles = nextRoles.filter((x) => x != name);
    } else {
      nextRoles.push(name);
    }
    const nextState = {
      ...state,
      user: {
        ...state.user,
        roles: nextRoles,
      },
    };
    setState(nextState);
  };

  const handleEmail = (email) => {
    const nextState = {
      ...state,
      user: {
        ...state.user,
        email: email,
      },
    };

    const test = validateEmail(email);
    if (email == null || !validateEmail(email)) {
      nextState.errors.emailErr = "Email is not valid";
    } else {
      nextState.errors.emailErr = "";
    }
    setState(nextState);
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateData = () => {
    handleUsername(state.user.userName);
    handleEmail(state.user.email);
    return (
      state.errors.usernameErr.length == 0 && state.errors.emailErr.length == 0
    );
  };

  const isCheckedRole = (r) => {
    return state.user.roles != null && state.user.roles.includes(r);
  };

  return (
    <React.Fragment>
      <div className="jss130">
        <div className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <h3>Account information</h3>
            <Grid item xs={12}>
              <TextField
                id="username"
                label="Username"
                error={
                  state.errors.usernameErr != null &&
                  state.errors.usernameErr.length > 0
                }
                helperText={state.errors.usernameErr}
                value={state.user.userName}
                disabled={!isCreate}
                type="text"
                fullWidth
                onChange={(e) => handleChange(e, "username")}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                error={
                  state.errors.emailErr != null &&
                  state.errors.emailErr.length > 0
                }
                helperText={state.errors.emailErr}
                value={state.user.email}
                type="text"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange(e, "email")}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Roles</FormLabel>
                <FormGroup row>
                  {roles.map((r, index) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckedRole(r)}
                          onChange={(e) => handleChange(e, "role")}
                          name={r}
                          color="primary"
                        />
                      }
                      label={r}
                      key={index}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider />
          <div className={classes.foot}>
            <Button variant="contained" color="primary" onClick={submit}>
              {" "}
              Save{" "}
            </Button>
            <Button variant="contained" onClick={goBack}>
              {" "}
              Go back{" "}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
