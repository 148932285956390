import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import DeviceService from "../../../services/device";
import Filter1Icon from "@material-ui/icons/Filter1";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    borderTop: '1px solid #383e5d'
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  summaryBox: {
    display: "inline-block",
    border: "1px solid #384467",
    backgroundColor: "#262445",
    borderRadius: "25px",
    padding: "5px",
    margin: "5px",
  },
  summaryFab: {
    marginLeft: "1px",
    marginRight: "1px",
    width: "29px",
    height: "29px",
    minHeight: "10px !important",
  },
  controlButton: {
    cursor: 'pointer'
  },
  fabSeverityGood: {
    backgroundColor: "#3bd949",    
  },
  fabSeverityModerate: {
    backgroundColor: "#ffd500",
  },
  fabSeveritySensitiveUnhealthy: {
    backgroundColor: "#ff7e00",
  },
  fabSeverityUnhealthy: {
    backgroundColor: "#ff0017",
    color: '#fff'
  },
  fabSeverityVeryUnhealthy: {
    backgroundColor: "#99004c",
    color: '#fff'
  },
  fabSeverityHazardous: {
    backgroundColor: "#7e0023",
    color: '#fff'
  },
  fabOffline: {
    border: '1px solid red',
    backgroundColor: "#7d7d7d",
    color: '#fff'
  },
  fabOff: {
    backgroundColor: "#7d7d7d",
    color: '#fff'
  }
}));
export default function BottomAppBar({ devices, handleOnlineStatus, filterServerity, resetFilter }) {
  // console.log(devices);
  const classes = useStyles();
  const [aggregateState, setAggregateState] = useState({
    serverity: [0, 0, 0, 0, 0, 0],
    offline: 0,
    off: 0,
  });

  useEffect(() => {
    const serverity = DeviceService.aggregateDevices(devices);
    const offline = devices?.filter((d) => !d.monitor.isOnline).length;
    const off = devices?.filter(
      (d) => d.monitor.mode == 17 || d.monitor.mode == 18
    ).length;
    setAggregateState({
      serverity: serverity,
      offline: offline,
      off: off,
    });
  }, [devices]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <span className={"round-border " + classes.summaryBox}>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeverityGood} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(0)}
            >
              <span>{aggregateState.serverity[0]}</span>
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeverityModerate} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(1)}
            >
              <span>{aggregateState.serverity[1]}</span>
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeveritySensitiveUnhealthy} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(2)}
            >
              <span>{aggregateState.serverity[2]}</span>
              {/* {aggregateState.serverity[2]} */}
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeverityUnhealthy} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(3)}
            >
              <span>{aggregateState.serverity[3]}</span>
              {/* {aggregateState.serverity[3]} */}
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeverityVeryUnhealthy} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(4)}
            >
              <span>{aggregateState.serverity[4]}</span>
              {/* {aggregateState.serverity[4]} */}
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabSeverityHazardous} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => filterServerity(5)}
            >
              <span>{aggregateState.serverity[5]}</span>
              {/* {aggregateState.serverity[5]} */}
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabOffline} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => handleOnlineStatus('0')}
            >
              <span>{aggregateState.offline}</span>
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.fabOff} ${classes.controlButton}`}
              // color="primary"
              aria-label="add" onClick={() => handleOnlineStatus('3')}
            >
              <span>{aggregateState.off}</span>
            </Fab>
            <Fab
              className={`${classes.summaryFab} ${classes.controlButton}`}
              color="secondary" 
              aria-label="add" onClick={() => resetFilter()}
            >
              <HighlightOffIcon />
            </Fab>
          </span>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
