import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import "./tenant.css";
import DeviceAliasAutoComplete from '../../utilities/device-alias-autocomplete';
import TenantService from '../../../services/tenant';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "89%",
  },
  formControl: {
    margin: theme.spacing(2),
  },
  foot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function TenantDetail({ tenant }) {
  const classes = useStyles();
  const [tenantState, setTenantState] = useState({
    id: "",
    name: "",
    code: "",
    aliases: [],
    errors: {
      nameError: '',
      codeError: ''
    }
  });
  const [] = useState([]);

  useEffect(() => {
    if (tenant != null) {
      setTenantState({
        ...tenant,
        errors: {
          nameError: '',
          codeError: ''
        }
      });
    }
  }, [tenant]);

  const handleChangeName = (tName) => {
    let nextState = {
      ...tenantState,
      name: tName,
    };

    if (tName == null || tName.length == 0) {
      nextState.errors.nameError = 'Tenant name can not be empty!';
    } else {
      nextState.errors.nameError = '';
    }

    setTenantState(nextState);
  };

  const handleChangeCode = (tCode) => {
    let nextState = {
      ...tenantState,
      code: tCode,
    };

    if (tCode == null || tCode.length == 0) {
      nextState.errors.codeError = 'Tenant code can not be empty!';
    } else {
      nextState.errors.codeError = '';
    }

    setTenantState(nextState);
  };

  const handleChangeAlias = (values) => {
    setTenantState({
      ...tenantState,
      aliases: values
    });
  }

  const submit = async () => {
    const tenant = {
      ...tenantState,
      errors: null
    };
    try {
      await TenantService.add(tenant);
      alert('Tenant changed successfully!');
    } catch (err) {
      alert(err);
    }
  };

  const goBack = () => {};

  return (
    <React.Fragment>
      <div className="jss130">
        <div className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <h3>Domain</h3>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Tenant name"
                error={tenantState.name == null || tenantState.name.length == 0}
                helperText={tenantState.errors.nameError}
                value={tenantState.name}
                // disabled={tenantState.id != null && tenantState.id != ""}
                type="text"
                fullWidth
                onChange={(e) => handleChangeName(e.target.value)}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="code"
                label="Tenant code"
                error={tenantState.code == null || tenantState.code.length == 0}
                helperText={tenantState.errors.codeError}
                value={tenantState.code}
                // disabled={tenantState.id != null && tenantState.id != ""}
                type="text"
                fullWidth
                onChange={(e) => handleChangeCode(e.target.value)}
                // variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <DeviceAliasAutoComplete value={tenantState.aliases? tenantState.aliases : []} onChange={handleChangeAlias}/>
            </Grid>
          </Grid>
          <Divider />
          <div className={classes.foot}>
            <Button variant="contained" color="primary" onClick={submit}>
              {" "}
              Save{" "}
            </Button>
            <Button variant="contained" onClick={goBack}>
              {" "}
              Go back{" "}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
