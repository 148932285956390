import logo from "./logo.svg";
import "./App.css";
import { PublicRoute } from "./routing";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";

// https://stackoverflow.com/questions/47804380/styling-the-placeholder-in-a-textfield/57995968#57995968
const customTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      input: {
        "&::placeholder": {
          color: "white",
        },
        color: "white", // if you also want to change the color of the input, this is the prop you'd use
      },
    },
    MuiOutlinedInput: {
      input: {
        "&::placeholder": {
          color: "white",
        },
        color: "white", // if you also want to change the color of the input, this is the prop you'd use
      },
    },
    MuiFormLabel: { root: { color: 'white' }, color: 'white'},
  },
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <div className="App">
        <PublicRoute />
      </div>
    </ThemeProvider>
  );
}

export default App;
