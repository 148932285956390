import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import DoneOutlineOutlinedIcon from "@material-ui/icons/DoneOutlineOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import IntelModConfigService from "../../services/intel-mod";
import DeviceService from "../../services/device";
import InputLabel from "@material-ui/core/InputLabel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  footer: {
    marginTop: "8px",
  },
});

const ConfigProperty = {
  minCo2: "minCo2",
  maxCo2: "maxCo2",
  minAqi: "minAqi",
  maxAqi: "maxAqi",
  rank: "rank",
  air: "air",
};

const defaultSituation = {
  condition: {
    minCo2: 0,
    minAqi: 0,
    maxCo2: 0,
    maxAqi: 0,
  },
  command: {
    rank: 1,
    air: 0,
  },
};

export default function IntelModConfig() {
  const classes = useStyles();
  const [devices, setDevices] = useState([]);
  const [intelConfig, setIntelConfig] = useState({
    id: 0,
    enabled: false,
    devices: [],
    situations: [],
  });
  const [situationBuffer, setSituationBuffer] = useState(defaultSituation);
  const [state, setState] = useState({
    checkedAll: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    fetchDevices();
    const config = await IntelModConfigService.get();
    setIntelConfig(config);
  };

  const fetchDevices = async () => {
    let response = await DeviceService.getDevices({ start: 0, length: 0 });
    response = response.sort(compareDevice);
    setDevices(response);
  };

  const compareDevice = ( a, b ) => {
    if ( a.managedDeviceAlias < b.managedDeviceAlias ){
      return -1;
    }
    if ( a.managedDeviceAlias > b.managedDeviceAlias ){
      return 1;
    }
    return 0;
  }

  const handleChangeBuffer = (e, prop) => {
    let nextSituationBuffer = {
      condition: {
        ...situationBuffer.condition,
      },
      command: {
        ...situationBuffer.command,
      },
    };

    if (prop == ConfigProperty.minCo2) {
      nextSituationBuffer.condition.minCo2 = parseInt(e.target.value);
    }
    if (prop == ConfigProperty.minAqi) {
      nextSituationBuffer.condition.minAqi = parseInt(e.target.value);
    }
    if (prop == ConfigProperty.maxCo2) {
      nextSituationBuffer.condition.maxCo2 = parseInt(e.target.value);
    }
    if (prop == ConfigProperty.maxAqi) {
      nextSituationBuffer.condition.maxAqi = parseInt(e.target.value);
    }
    if (prop == ConfigProperty.rank) {
      nextSituationBuffer.command.rank = parseInt(e.target.value);
    }
    if (prop == ConfigProperty.air) {
      nextSituationBuffer.command.air = parseInt(e.target.value);
    }
    setSituationBuffer(nextSituationBuffer);
  };

  const deleteSituation = async (index) => {
    if (!window.confirm(`Are you sure to delete this situation?`)) {
      return;
    }
    let arr = intelConfig.situations;
    arr.splice(index, 1);
    setIntelConfig({
      ...intelConfig,
      situations: arr,
    });
  };

  const createNewSituation = () => {
    let situations = [...intelConfig.situations];
    situations.push({ ...situationBuffer });
    setIntelConfig({
      ...intelConfig,
      situations: situations,
    });
    setSituationBuffer(defaultSituation);
  };

  const isCheckedAlias = (device) => {
    return intelConfig.devices.includes(device.id);
  };

  const enableConfig = (e) => {
    setIntelConfig({
      ...intelConfig,
      enabled: e.target.checked
    });
  }

  const handleChangeAlias = (e, d) => {
    const isChecked = e.target.checked;
    let selectedDevices = intelConfig.devices;
    if (isChecked) {
      selectedDevices.push(d.id);
    } else {
      selectedDevices = selectedDevices.filter((x) => x != d.id);
    }
    setIntelConfig({
      ...intelConfig,
      devices: selectedDevices,
    });
  };

  const handleCheckAllAlias = (e) => {
    const checkedAll = e.target.checked;
    let seletedDevice = [];
    if (checkedAll) {
      seletedDevice = devices.map((x) => x.id);
    }
    setIntelConfig({
      ...intelConfig,
      devices: seletedDevice,
    });
    setState({
      ...state,
      checkedAll: checkedAll,
    });
  };

  const submit = async () => {
    try{
      await IntelModConfigService.update(intelConfig.id, intelConfig);
      alert("Config changed successfully");
    } catch (err) {
      alert(err);
    }
  };

  return (
    <React.Fragment>
      <h3>Auto mode</h3>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{width: '15%'}}>Min co2</TableCell>
                    <TableCell align="center" style={{width: '15%'}}>Max co2</TableCell>
                    <TableCell align="center" style={{width: '15%'}}>Min Aqi</TableCell>
                    <TableCell align="center" style={{width: '15%'}}>Max Aqi</TableCell>
                    <TableCell align="center">Rank</TableCell>
                    <TableCell align="center">Air (external only)</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {intelConfig.situations?.map((i, index) => (
                    <TableRow className="active-row" key={index}>
                      <TableCell align="center">{i.condition.minCo2}</TableCell>
                      <TableCell align="center">{i.condition.maxCo2}</TableCell>
                      <TableCell align="center">
                        {i.condition.minAqi}{" "}
                      </TableCell>
                      <TableCell align="center">{i.condition.maxAqi}</TableCell>
                      <TableCell align="center">{i.command.rank}</TableCell>
                      <TableCell align="center">{i.command.air} %</TableCell>
                      <TableCell align="center">
                        <Button
                          color="secondary"
                          onClick={() => deleteSituation(index)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="active-row">
                    <TableCell component="th" scope="row">
                      <TextField
                        id="minCo2"
                        label="Min co2"
                        value={situationBuffer.condition.minCo2}
                        type="number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleChangeBuffer(e, ConfigProperty.minCo2)
                        }
                        // variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        id="maxCo2"
                        label="Max co2"
                        value={situationBuffer.condition.maxCo2}
                        type="number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleChangeBuffer(e, ConfigProperty.maxCo2)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        id="minAqi"
                        label="Min aqi"
                        value={situationBuffer.condition.minAqi}
                        type="number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleChangeBuffer(e, ConfigProperty.minAqi)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        id="maxAqi"
                        label="Max aqi"
                        value={situationBuffer.condition.maxAqi}
                        type="number"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          handleChangeBuffer(e, ConfigProperty.maxAqi)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="rank-select">Rank</InputLabel>
                        <Select
                          native
                          value={situationBuffer.command.rank}
                          onChange={(e) => handleChangeBuffer(e, ConfigProperty.rank)}
                          inputProps={{
                            name: "Rank",
                            id: "rank-select",
                          }}
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">                      
                       <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="air-select">Airflow</InputLabel>
                        <Select
                          native
                          value={situationBuffer.command.air}
                          onChange={(e) => handleChangeBuffer(e, ConfigProperty.air)}
                          inputProps={{
                            name: "Air flow",
                            id: "air-select",
                          }}
                        >
                          <option value={0}>Internal</option>
                          <option value={25}>25% external</option>
                          <option value={50}>50% external</option>
                          <option value={75}>75% external</option>
                          <option value={100}>100% external</option>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="center">
                      <Button color="primary" onClick={createNewSituation}>
                        <AddCircleIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedAll}
                  onChange={handleCheckAllAlias}
                  name="all"
                  color="primary"
                />
              }
              label="Select all"
              style={{ marginBottom: "5px" }}
            />
            <Paper style={{ maxHeight: 289, overflow: "auto" }}>
              {devices.map((a, index) => (
                <Box display="inline" key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckedAlias(a)}
                        onChange={(e) => {
                          handleChangeAlias(e, a);
                        }}
                        name={a.managedDeviceAlias}
                        color="primary"
                      />
                    }
                    label={a.managedDeviceAlias}
                  />
                </Box>
              ))}
            </Paper>
            
            <Grid item xs={12} sm={12}>
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        intelConfig.enabled
                      }
                      onChange={(e) => enableConfig(e)}
                      name="Enabled"
                    />
                  }
                  label="Enabled"
                />
              </FormControl>
            </Grid>
            
            <div className={classes.footer}>
              <Button variant="contained" color="primary" onClick={submit}>
                {" "}
                Save{" "}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
