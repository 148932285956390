import axios from "../axios-client";
import AppConfig from "../../config";
import RequestService from '../request.service';

class IntelModConfigService {
    async get() {
        return await RequestService.get(AppConfig.intelModConfig.getUrl);
    }

    async update(id, config) {
        return await RequestService.put(`${AppConfig.intelModConfig.updateUrl}/${id}`, config);
    }
}

export default new IntelModConfigService();