import React, { useState, useEffect, useLayoutEffect } from "react";
import Chart from "react-google-charts";

export default function AreaChartWrapper({ columns, rows, yAxisLabel, xAxisLabel, title, color }) {
  const useWindowSize = (w, h) => {
    const [windowSize, setWindowSize] = useState({
      width: w,
      height: h,
    });

    useLayoutEffect(() => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }, []);

    return windowSize;
  };

  const wdSize = useWindowSize(900);
  const [chartSize, setChartSize] = useState({
    width: "100%",
    height: "500px",
  });

  /// screen resize, happen on mobile
  useEffect(() => {
      // size for mobile device
    if (wdSize.width <= 900) {
      setChartSize({
        width: "600px",
        height: "300px",
      });
    } else {
      setChartSize({
        width: "100%",
        height: "500px",
      });
    }
  }, [wdSize.width]);
  // ----------------------responsive -----------------------

  return (
      <React.Fragment>
          <Chart
            chartType="AreaChart"
            width={chartSize.width}
            height={chartSize.height}
            legendToggle
            rows={rows}
            columns={columns}
            options={{
              title: title,
              hAxis: { title: xAxisLabel, titleTextStyle: { color: "#333" } },
              vAxis: {
                minValue: 0,
                title: yAxisLabel,
                titleTextStyle: { color: "#333" },
              }, colors: [color]
              // For the legend to fit, we make the chart area smaller
              // chartArea: { width: "82%", height: "80%"   },
              // chartArea:{right: 120}
              // lineWidth: 25
            }}
          />
      </React.Fragment>
  )
}
