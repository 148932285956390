import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AuthService from '../../../services/auth';

export default function ResetPasswordDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [state, setState] = useState({
    changePasswordInput: {
      oldPassword: "",
      confirmPassword: "",
      newPassword: "",
    },
    errors: {
      oldPasswordErr: "",
      confirmPasswordErr: "",
      newPasswordErr: "",
    },
  });

  const handleChange = (e, type) => {
    switch (type) {
      case "oldPassword":
        handleOldPassword(e.target.value);
        return;
      case "confirmPassword":
        handleConfirmPassword(e.target.value);
        return;
      case "newPassword":
        handleNewPassword(e.target.value);
        return;
      default:
        return;
    }
  };

  const handleOldPassword = (pw) => {
    const nextState = {
      ...state,
      changePasswordInput: {
        ...state.changePasswordInput,
        oldPassword: pw,
      },
    };

    if (pw == null || pw.length == 0) {
      nextState.errors.oldPasswordErr = "Old password is not valid";
    } else {
      nextState.errors.oldPasswordErr = "";
    }
    setState(nextState);
  };

  const handleConfirmPassword = (pw) => {
    const nextState = {
      ...state,
      changePasswordInput: {
        ...state.changePasswordInput,
        confirmPassword: pw,
      },
    };

    if (pw == null || pw.length == 0) {
      nextState.errors.confirmPasswordErr = "Confirm password is not valid";
    } else if (pw !== state.changePasswordInput.oldPassword) {
      nextState.errors.confirmPasswordErr =
        "Confirm password does not match old password";
    } else {
      nextState.errors.confirmPasswordErr = "";
    }
    setState(nextState);
  };

  const handleNewPassword = (pw) => {
    const nextState = {
      ...state,
      changePasswordInput: {
        ...state.changePasswordInput,
        newPassword: pw,
      },
    };

    if (pw == null || pw.length == 0) {
      nextState.errors.newPasswordErr = "New password is not valid";
    } else {
      nextState.errors.newPasswordErr = "";
    }
    setState(nextState);
  };

  const validateData = () => {
      handleOldPassword(state.changePasswordInput.oldPassword);
      handleConfirmPassword(state.changePasswordInput.confirmPassword);
      handleNewPassword(state.changePasswordInput.newPassword);
      return (state.errors.oldPasswordErr.length === 0 && state.errors.newPasswordErr.length === 0 
        && state.errors.confirmPasswordErr.length === 0);
  };

  const submit = async() => {
      if (validateData() === true) {
        try {
            await AuthService.changePassword(state.changePasswordInput);
            alert('Change password successful!');
            handleClose();
        } catch (error) {
            alert(error);
        }
      }
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <LockOpenIcon /> Change password
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="oldpwd"
            label="Old password"
            type="password"
            error={
              state.errors.oldPasswordErr != null &&
              state.errors.oldPasswordErr.length > 0
            }
            helperText={state.errors.oldPasswordErr}
            value={state.changePasswordInput.oldPassword}
            onChange={(e) => handleChange(e, "oldPassword")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="confirmpwd"
            label="Confirm Password"
            type="password"
            error={
              state.errors.confirmPasswordErr != null &&
              state.errors.confirmPasswordErr.length > 0
            }
            helperText={state.errors.confirmPasswordErr}
            value={state.changePasswordInput.confirmPassword}
            onChange={(e) => handleChange(e, "confirmPassword")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New password"
            type="password"
            error={
              state.errors.newPasswordErr != null &&
              state.errors.newPasswordErr.length > 0
            }
            helperText={state.errors.newPasswordErr}
            value={state.changePasswordInput.newPassword}
            onChange={(e) => handleChange(e, "newPassword")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={submit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
