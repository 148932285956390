import axiosClient from "./axios-client";

class RequestService {
  async get(url) {
    const response = await axiosClient.get(url);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  async post(url, obj) {
    const response = await axiosClient.post(url, obj);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  async put(url, obj) {
    const response = await axiosClient.put(url, obj);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }

  async patch(url, obj) {
    const response = await axiosClient.patch(url, obj);
    if (response.status != 200) {
      throw new Error("Error code " + response.status);
    }
    const rs = response.data;
    return rs;
  }
}

export default new RequestService();