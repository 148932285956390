require("dotenv").config();

const env = process.env;
const AppConfig = {
  apiUrl: env.REACT_APP_API_URL,
  auth: {
    tokenUrl: env.REACT_APP_API_URL + env.REACT_APP_AUTH_URL,
    changePasswordUrl: env.REACT_APP_API_URL + "/api/auth/password/change",
    tokenKey: "session",
    username: "username",
  },
  device: {
    listDeviceUrl: env.REACT_APP_API_URL + env.REACT_APP_DEVICE_LIST_URL,
    deviceUrl: env.REACT_APP_API_URL + env.REACT_APP_DEVICE_URL,
    turnOnDeviceURL: env.REACT_APP_API_URL + env.REACT_APP_DEVICE_TURN_ON_URL,
    turnOffDeviceURL: env.REACT_APP_API_URL + env.REACT_APP_DEVICE_TURN_OFF_URL,
    setFanUrl: env.REACT_APP_API_URL + "/api/device/fan",
    setAirUrl: env.REACT_APP_API_URL + "/api/device/air",
    resetFilterUrl: env.REACT_APP_API_URL + "/api/device/resetfilter",
    getAliasUrl: env.REACT_APP_API_URL + "/api/device/alias",
    executeAliasUrl: env.REACT_APP_API_URL + "/api/device/alias/execute",
    getDeviceAliasUrl: env.REACT_APP_API_URL + "/api/device/devicealias",
    updateDeviceAliasUrl: env.REACT_APP_API_URL + "/api/device/alias/update",
    mode: {
      offline: 17,
    },
    func: {
      heatOn: "40000",
      airCommand: {
        NONE: "000",
        INTERIOR: "010",
        PCTL25: "080",
        PCTL50: "100",
        PCTL75: "180",
        EXTERIOR: "200",
      },
      prefix: "0211",
    },
  },
  tenant: {
    getAllUrl: env.REACT_APP_API_URL + "/api/tenant",
    addUrl: env.REACT_APP_API_URL + "/api/tenant",
    updateUrl: env.REACT_APP_API_URL + "/api/tenant",
  },
  calendar: {
    getAllUrl: env.REACT_APP_API_URL + "/api/calendar",
    upsertUrl: env.REACT_APP_API_URL + "/api/calendar",
    deleteUrl: env.REACT_APP_API_URL + "/api/calendar",
  },
  user: {
    getAllUrl: env.REACT_APP_API_URL + "/api/user",
    createUrl: env.REACT_APP_API_URL + "/api/user/create",
    updateUrl: env.REACT_APP_API_URL + "/api/user/update",
    deleteUrl: env.REACT_APP_API_URL + "/api/user",
  },
  intelModConfig: {
    getUrl: env.REACT_APP_API_URL + "/api/intelmod",
    updateUrl: env.REACT_APP_API_URL + "/api/intelmod",
  },
  deviceHistory: {
    addUrl: env.REACT_APP_API_URL + "/api/devicehistory",
    getHourlyDeviceHistory: env.REACT_APP_API_URL + "/api/devicehistory",
  },
  configs: {
    BeList: [
      { text: "B2", value: "B2" },
      { text: "B3", value: "B3" },
      { text: "B4", value: "B4" },
      { text: "B5", value: "B5" },
      { text: "B6", value: "B6" },
      { text: "B7", value: "B7" },
      { text: "B8A", value: "B8A" },
      { text: "B9", value: "B9" },
      { text: "B10", value: "B10" },
      { text: "B11", value: "B11" },
      { text: "All", value: "" },
    ],
    floors: [
      { text: "All", value: -1 },
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
    ],
    jwtPayload: {
      user: "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
      role: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
    },
    minutes: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
    ],
    Aqi: [
      4,
      4,
      8,
      13,
      17,
      21,
      25,
      29,
      33,
      38,
      42,
      46,
      50,
      52,
      54,
      56,
      59,
      61,
      63,
      65,
      67,
      69,
      71,
      73,
      76,
      78,
      80,
      82,
      84,
      86,
      88,
      90,
      93,
      95,
      97,
      99,
      101,
      104,
      106,
      109,
      111,
      114,
      116,
      119,
      121,
      124,
      126,
      129,
      131,
      134,
      136,
      139,
      141,
      144,
      146,
      149,
      150,
      151,
      151,
      152,
      152,
      153,
      153,
      154,
      154,
      155,
      156,
      156,
      157,
      157,
      158,
      158,
      159,
      159,
      160,
      160,
      161,
      161,
      162,
      162,
      163,
      163,
      164,
      164,
      165,
      166,
      166,
      167,
      167,
      168,
      168,
      169,
      169,
      170,
      170,
      171,
      171,
      172,
      172,
      173,
      173,
      174,
      174,
      175,
      176,
      176,
      177,
      177,
      178,
      178,
      179,
      179,
      180,
      180,
      180,
      181,
      182,
      182,
      183,
      183,
      184,
      184,
      185,
      186,
      186,
      187,
      187,
      188,
      188,
      189,
      189,
      190,
      190,
      191,
      191,
      192,
      192,
      193,
      193,
      194,
      194,
      195,
      196,
      196,
      197,
      197,
      198,
      198,
      199,
      199,
      200,
      201,
      202,
      203,
      204,
      205,
      206,
      207,
      208,
      209,
      210,
      211,
      212,
      213,
      214,
      215,
      216,
      217,
      218,
      219,
      220,
      221,
      222,
      223,
      224,
      225,
      226,
      227,
      228,
      229,
      230,
      231,
      232,
      233,
      234,
      235,
      236,
      237,
      238,
      239,
      240,
      241,
      242,
      243,
      244,
      245,
      246,
      247,
      248,
      249,
      250,
      251,
      252,
      253,
      254,
      255,
      256,
      257,
      258,
      259,
      260,
      261,
      262,
      263,
      264,
      265,
      266,
      267,
      268,
      269,
      270,
      271,
      272,
      273,
      274,
      275,
      276,
      277,
      278,
      279,
      280,
      281,
      282,
      283,
      284,
      285,
      286,
      287,
      288,
      289,
      290,
      291,
      292,
      293,
      294,
      295,
      296,
      297,
      298,
      299,
      300,
      301,
      302,
      303,
      304,
      305,
      306,
      307,
      308,
      309,
      310,
      311,
      312,
      313,
      314,
      315,
      316,
      317,
      318,
      319,
      320,
      321,
      322,
      323,
      324,
      325,
      326,
      327,
      328,
      329,
      330,
      331,
      332,
      333,
      334,
      335,
      336,
      337,
      338,
      339,
      340,
      341,
      342,
      343,
      344,
      345,
      346,
      347,
      348,
      349,
      350,
      351,
      352,
      353,
      354,
      355,
      356,
      357,
      358,
      359,
      360,
      361,
      362,
      363,
      364,
      365,
      366,
      367,
      368,
      369,
      370,
      371,
      372,
      373,
      374,
      375,
      376,
      377,
      378,
      379,
      380,
      381,
      382,
      383,
      384,
      385,
      386,
      387,
      388,
      389,
      390,
      391,
      392,
      393,
      394,
      395,
      396,
      397,
      398,
      399,
      400,
      400,
      401,
      402,
      402,
      403,
      404,
      404,
      405,
      406,
      406,
      407,
      408,
      408,
      409,
      410,
      410,
      411,
      412,
      412,
      413,
      414,
      414,
      415,
      416,
      416,
      417,
      418,
      418,
      419,
      420,
      420,
      421,
      422,
      422,
      423,
      424,
      424,
      425,
      426,
      426,
      427,
      428,
      428,
      429,
      430,
      430,
      431,
      432,
      432,
      433,
    ],
  },
};

export default AppConfig;
