
import axios from '../axios-client';
import AppConfig from '../../config';

class UserService {
    async getAll() {
        const response = await axios.get(AppConfig.user.getAllUrl);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }

    async create(user) {
        const response = await axios.post(AppConfig.user.createUrl, user);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }

    async update(user) {
        const response = await axios.post(AppConfig.user.updateUrl, user);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }

    async delete(username) {
        const response = await axios.delete(AppConfig.user.deleteUrl + '/' + username);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }
}

export default new UserService();