import RequestService from '../request.service';
import AppConfig from "../../config";

class TenantService {
  async getAll() {
      return await RequestService.get(AppConfig.tenant.getAllUrl);
  }

  async add(tenant) {
      return await RequestService.post(AppConfig.tenant.addUrl, tenant);
  }

  async update(tenant) {
      return await RequestService.put(AppConfig.tenant.updateUrl, tenant);
  }
}

export default new TenantService();
