import React, { useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeviceService from "../../../services/device";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function DeviceAliasAutoComplete({value, onChange}) {
  const [options, setOptions] = useState([
    {
      id: 0,
      alias: "",
    },
  ]);

  useEffect(() => {
    initial();
  }, []);

  const initial = async () => {
    const deviceAlias = await DeviceService.getDeviceAliasInfo();
    setOptions(deviceAlias);
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        value={value}
        getOptionLabel={(option) => option.alias}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.alias}
          </React.Fragment>
        )}
        // style={{ width: 500 }}
        onChange={(event, value) => onChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label=""
            placeholder="Select devices"
          />
        )}
      />
    </React.Fragment>
  );
}
