import axios from '../axios-client';
import AppConfig from '../../config';

class CalendarService {
    async getAll() {
        const response = await axios.get(AppConfig.calendar.getAllUrl);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }

    async upsert(calendar) {
        const response = await axios.post(AppConfig.calendar.upsertUrl, calendar);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }

    async delete(id) {
        const response = await axios.delete(AppConfig.calendar.deleteUrl + '/' + id);  
        if (response.status != 200) {
            throw new Error('Error code ' + response.status);
        }
        const rs = response.data;
        return rs;
    }
}

export default new CalendarService();